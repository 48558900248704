@import 'ht-variables';
@import 'ht-typography';

.analytics-tab {
  display: flex;
  flex-direction: column;
  gap: $spacing-ml;
}

.analytics-section {
  display: flex;
  flex-direction: column;
  gap: $spacing-sm;
  border: 1px solid $N100;
  border-radius: $radius-xs;
  padding: $spacing-sm $spacing-ml;

  .title {
    @extend .text-body-small;
    display: flex;
    align-items: end;
    gap: $spacing-xs;
    width: fit-content;
    cursor: default;

    > span {
      @extend .text-small;
      line-height: 23px !important;
    }

    i {
      font-size: 10px;
      color: $N300;
      align-self: center;
    }
  }

  .analytics-table-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: $spacing-ml;

    .table-actions {
      display: flex;
      gap: $spacing-sm;
    }
  }
}

.analytics-section-table {
  display: flex;
  flex-direction: column;
  gap: $spacing-sm;

  .analytics-section-table-title {
    @extend .text-body;
    @extend .bold;
    color: $N700;

    i {
      color: $N300;
    }
  }
}

.analytics-filters-row {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: $spacing-sm;

  // TODO: Instead of using a button secondary, use a icon button to this, or, fix the size change (because the gap) when the button is loading
  .analytics-filters-row-download-button {
    gap: 0 !important;
  }
}
